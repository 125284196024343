import React, { Fragment } from "react"
/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    software Engineer, living in <a href="https://en.wikipedia.org/wiki/Adelaide">Adelaide, Australia </a> Interested in building engaging products and solving problems
  </Fragment>
)
